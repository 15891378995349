import './GameOutput.scss';

function GameOutput({ messages, gameVars, gameItems }) {
    console.log('gameItems', gameItems);
    const parseMessageText = (text) => {
        const msgVars = text.match(/\{\{(.*?)\}\}/g); // looks like: ["{{name}}","{{age}}"]
        if (msgVars) {
            msgVars.forEach((msgVar) => {
                const vKey = msgVar.replace(/[{}]+/g, '');
                if (vKey === 'INVENTORY_ITEMS') {
                    let invText = 'nothing';
                    if (gameItems.length === 1) {
                        invText = gameItems[0];
                    } else if (gameItems.length > 1) {
                        const gameItemsClone = JSON.parse(JSON.stringify(gameItems));
                        const lastItem = gameItemsClone.pop();
                        invText = gameItemsClone.join(', ') + ' and ' + lastItem;
                    }
                    text = text.replaceAll(msgVar, invText);
                } else if (gameVars[vKey]) {
                    text = text.replaceAll(msgVar, gameVars[vKey]);
                } else {
                    console.error('No var named "' + vKey + '".', gameVars);
                }
            });
        }
        return text;
    };

    return (
        <div className="Game-output">
            {messages.map((msg, index) => {
                const sharedClasses = [
                    msg.isUser ? 'user-message' : 'system-message',
                    `message-type-${msg.messageType}`,
                ];
                let msgText = parseMessageText(msg.text);
                return (
                    <div
                        key={'output-ms-' + index}
                        className={['Game-output-message-wrapper', ...sharedClasses].join(' ')}
                    >
                        <div className={['Game-output-message', ...sharedClasses].join(' ')}>{msgText}</div>
                    </div>
                );
            })}
        </div>
    );
}

export default GameOutput;

import Game from '../../arcnyx/Game';
import './GameBox.scss';

function GameBox(props) {
    return (
        <div className="Game-box">
            <Game commandInputRef={props.commandInputRef} />
        </div>
    );
}

export default GameBox;

import { useState } from 'react';
import './GameInput.scss';
import { inputEventHandler } from './inputEventHandler';

function GameInput({ commandInputRef, submitCommand, inputDisabled, commandIndex, setCommandIndex, commandHistory }) {
    const [inputValue, setInputValue] = useState('');

    const submitCommandProxy = () => {
        if (inputValue) submitCommand(inputValue);
        clearInput();
    };

    const clearInput = () => {
        setInputValue('');
        if (commandInputRef.current) {
            commandInputRef.current.value = '';
        }
    };

    const inputEvent = (evt) => {
        inputEventHandler({
            evt,
            setInputValue,
            submitCommand: submitCommandProxy,
            commandIndex,
            setCommandIndex,
            commandHistory,
        });
    };

    return (
        <div className="Game-input">
            <input
                ref={commandInputRef}
                type="text"
                autoFocus
                onKeyUp={inputEvent}
                disabled={inputDisabled}
                placeholder="Enter command"
            />
            <button type="button" onClick={submitCommandProxy}>
                Submit
            </button>
        </div>
    );
}

export default GameInput;

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';
import './AppLinks.scss';

function AppLinks() {
    return (
        <div className="App-links">
            <a href="https://www.linkedin.com/in/kilsch" className="App-link" target="_blank" rel="noreferrer">
                <FontAwesomeIcon icon={faLinkedin} /> LinkedIn
            </a>
        </div>
    );
}

export default AppLinks;

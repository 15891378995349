class Message {
    text;
    isUser;
    isSystem;
    messageType;

    constructor(messageText, isUser, messageType) {
        this.text = messageText;
        this.isUser = !!isUser;
        this.isSystem = !this.isUser;
        this.messageType = messageType || 'narrative';
    }
}

export class SystemMessage extends Message {
    constructor(messageText, messageType) {
        super(messageText, false, messageType);
    }
}

export class UserMessage extends Message {
    constructor(messageText, messageType) {
        super(messageText, true, messageType);
    }
}

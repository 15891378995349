// Action Parsing

export function isAction({ action, actionType, inputCommand }) {
    const actionTarget = action.targets || action.target ? action.targets || [action.target] : [];
    const strict = !!action.strict;
    inputCommand = inputCommand.toLowerCase();
    // console.log('Checking to see if command "' + inputCommand + '" matches action type "' + actionType + '".', action);

    // Check for any/input
    if (actionType === 'any') return true;
    if (actionType === 'input') return true;

    // Decide on action commands
    // Start with the actionType in case no match is found
    let actionCommands = [actionType];

    // Greeting
    if (actionType === 'greeting') {
        actionCommands = ['hello', 'hi', 'yo', 'hey', 'whats up', 'what up', "what's up"];

        // Yes
    } else if (actionType === 'yes') {
        actionCommands = ['yes', 'affirmative', 'sure', 'ok'];

        // No
    } else if (actionType === 'no') {
        actionCommands = ['no', 'negative', 'negatory', 'nope', 'naw'];

        // Move
    } else if (actionType === 'move') {
        actionCommands = ['move', 'walk', 'go'];

        // Leave
    } else if (actionType === 'leave' || actionType === 'exit') {
        actionCommands = ['leave', 'exit', 'go', 'go out', 'go away'];

        // Inspect
    } else if (actionType === 'inspect' || actionType === 'look') {
        actionCommands = ['inspect', 'look', 'check', 'peer', 'glance'];

        // Look Under
    } else if (actionType === 'look under' || actionType === 'inspect under') {
        actionCommands = [
            'look under',
            'inspect under',
            'pick up',
            'peek under',
            'look underneath',
            'inspect underneath',
            'peek underneath',
        ];

        // Take
    } else if (actionType === 'take') {
        actionCommands = ['take', 'grab', 'obtain', 'get', 'pick up', 'reach'];

        // Use
    } else if (actionType === 'use') {
        actionCommands = ['use'];

        // Touch
    } else if (actionType === 'touch') {
        actionCommands = ['touch', 'press'];

        // Smell
    } else if (actionType === 'smell') {
        actionCommands = ['smell', 'sniff'];

        // Taste
    } else if (actionType === 'taste' || actionType === 'eat') {
        actionCommands = ['taste', 'lick', 'eat', 'bite', 'consume'];

        // Equip
    } else if (actionType === 'equip') {
        actionCommands = ['equip', 'put on', 'wear', 'put'];

        // Open
    } else if (actionType === 'open') {
        actionCommands = ['open', 'check'];

        // Get up
    } else if (actionType === 'get up') {
        actionCommands = ['get up', 'stand up', 'sit up', 'rise', 'stand'];

        // Yell
    } else if (actionType === 'yell') {
        actionCommands = ['yell', 'scream'];

        // Lockpick
    } else if (actionType === 'lockpick') {
        actionCommands = ['lockpick', 'use lockpick', 'use lockpicking', 'pick the lock', 'pick lock'];

        // Read
    } else if (actionType === 'read') {
        actionCommands = ['read', 'decypher'];
    }

    // If strict, match entire command to each option
    if (strict) {
        // console.log('Strict match?', actionCommands.includes(inputCommand));
        return actionCommands.includes(inputCommand);
    }

    // Check if inputCommand matches part of any action commands
    for (var i = 0; i < actionCommands.length; i++) {
        if (inputCommand.includes(actionCommands[i])) {
            // console.log('Command is a match!', actionCommands[i]);
            // If target exists, check for target also
            if (typeof actionTarget === 'string') {
                actionTarget = [actionTarget];
            }
            if (typeof actionTarget === 'object' && actionTarget.length > 0) {
                var commandTarget = inputCommand.substring(inputCommand.indexOf(actionCommands[i]) + 1);
                for (var j = 0; j < actionTarget.length; j++) {
                    if (commandTarget.includes(actionTarget[j])) {
                        // console.log('Target is a match!', actionTarget[j]);
                        return true;
                    }
                }
                // console.log('Target is -NOT- a match!', actionTarget);
                return false;
            }

            return true;
        }
    }
    // console.log('Command is -NOT- a match!', actionCommands);
    return false;
}

import { useState, useEffect } from 'react';
import './Game.scss';
import GameOutput from './components/GameOutput/GameOutput';
import GameInput from './components/GameInput/GameInput';
import { SystemMessage, UserMessage } from './models/Message';
import gameData from './data/escapeRoom.json';
import sharedGameEngine from './services/GameEngine/sharedGameEngine';

function Game({ commandInputRef }) {
    const [messages, setMessages] = useState([]);
    const [inputDisabled, setInputDisabled] = useState(true);
    const [commandIndex, setCommandIndex] = useState(0);
    const [commandHistory, setCommandHistory] = useState([]);
    const [gameVars, setGameVarStore] = useState({});
    const [gameInventory, setGameInventoryStore] = useState([]);
    const [activeNode, setActiveNode] = useState();

    const addSystemMessage = (msg, type) => {
        setMessages((prevMessages) => [...prevMessages, new SystemMessage(msg, type)]);
    };
    const focusInput = () => commandInputRef.current && commandInputRef.current.focus();
    const enableInput = () => {
        setInputDisabled(false);
        focusInput();
        setTimeout(focusInput, 200);
        setTimeout(focusInput, 500);
    };
    const disableInput = () => {
        setInputDisabled(true);
    };
    const setGameVar = (key, val) => {
        setGameVarStore((prevGameVars) => ({ ...prevGameVars, [key]: val }));
        console.log(`{{ Setting ${key} to ${val} }}`);
    };
    const addInventoryItem = (itemName) => {
        if (!gameInventory.includes(itemName)) {
            console.log(`{{ Adding ${itemName} to inventory }}`);
            setGameInventoryStore((prevGameInventory) => [...prevGameInventory, itemName]);
        }
    };
    const removeInventoryItem = (itemName) => {
        console.log(`{{ ${itemName} removed from inventory }}`);
        setGameInventoryStore((prevGameInventory) => prevGameInventory.filter((key) => key !== itemName));
    };
    const setActiveNodeProxy = (node) => {
        // console.log('Active node has been set.', node, gameVars);
        setActiveNode(node);
    };

    // Load game engine
    // Do not pass state objects to the game engine
    // or else they will go stale (see stale closures)
    // https://dmitripavlutin.com/react-hooks-stale-closures/
    const delayOverride = process.env.NODE_ENV === 'development' ? 100 : null;
    const gameEngine = sharedGameEngine({
        gameData,
        enableInput,
        disableInput,
        addSystemMessage,
        setMessages,
        setCommandHistory,
        setActiveNode: setActiveNodeProxy,
        setGameVar,
        setGameVarStore,
        addInventoryItem,
        removeInventoryItem,
        setGameInventoryStore,
        delayOverride,
    });

    // Begin after a few seconds
    useEffect(() => {
        gameEngine.reset(); // helps with local dev (to avoid duplicate messages)
        const initDelay = process.env.NODE_ENV === 'development' ? 0 : 400;
        setTimeout(() => gameEngine.startGame(), initDelay);
        // eslint-disable-next-line
    }, []);

    // Submit command method
    const submitCommand = (command) => {
        if (!command) return;
        let messageType = 'dialogue';

        // Submit the command to the game engine
        const matchedAction = gameEngine.processCommand(command);
        if (matchedAction && matchedAction.inputType) {
            messageType = matchedAction.inputType;
        }

        // Add the command to user messages
        setMessages((prevMessages) => [...prevMessages, new UserMessage(command, messageType)]);
        setCommandHistory((prevCommands) => [...prevCommands, command]);
    };

    return (
        <div className="Game-arcnyx">
            <div className="Game-output-wrapper">
                <GameOutput messages={messages} gameVars={gameVars} gameItems={gameInventory} />
            </div>
            <div className="Game-input-wrapper">
                <GameInput
                    submitCommand={submitCommand}
                    commandInputRef={commandInputRef}
                    inputDisabled={inputDisabled}
                    commandIndex={commandIndex}
                    setCommandIndex={setCommandIndex}
                    commandHistory={commandHistory}
                />
            </div>
        </div>
    );
}

export default Game;
